import { useEffect } from 'react';
import packageJson from '../../package.json';

function CacheBuster(props) {
  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`)
      .then(response => response.json())
      .then(meta => {
        const shouldForceRefresh = meta.version !== packageJson.version;

        if (shouldForceRefresh) {
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          }
          window.location.reload(true);
        }
      });
  });

  return props.children;
}

export default CacheBuster;
