import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useTitle } from '../../helpers/hooks';

function Error(props) {
  const { classes } = props;

  useTitle('Oops');

  return (
    <Grid container justifyContent='center' alignItems='center' spacing={6}>
      <Grid item xs={12}>
        <Typography className={classes.centerContent}>
          An error has occurred please try reloading. If this continues please
          contact us at marketing@polotheatre.org or via Facebook
        </Typography>
      </Grid>
    </Grid>
  );
}

const styles = {
  centerContent: {
    textAlign: 'center',
    fontSize: '1.5em',
  },
};

export default withStyles(styles)(Error);
