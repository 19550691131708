import config from '../config';

const api = (endpoint, params = []) => {
  let url = `${config.api}${endpoint}`;

  if (params.length > 0) {
    url += `?`;
    params.forEach(param => {
      url += `${param.key}=${param.value}&`;
    });
  }

  return fetch(url)
    .then(res => res.json())
    .catch(error => ({ error }));
};

export const subscribeNewsletter = (
  firstName,
  lastName,
  email,
  address,
  city,
  state,
  zipCode,
  interests,
) => {
  return fetch(`${config.api}/api/newsletter`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipCode,
      interests,
    }),
  });
};

export const subscribeBusiness = (
  businessName,
  contactName,
  email,
  address,
  city,
  state,
  zip,
) => {
  return fetch(`${config.api}/api/newsletter/business`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      businessName,
      contactName,
      email,
      address,
      city,
      state,
      zip,
    }),
  });
};

export const submitAudienceFeedback = feedback => {
  return fetch(`${config.api}/api/audience-feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(feedback),
  });
};

export const submitVote = (paypalOrderId, showId) => {
  return fetch(`${config.api}/api/votes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      paypalOrderId,
      showId,
    }),
  });
};

export const purchaseSeasonTicket = (paypalOrderId, season) => {
  return fetch(`${config.api}/api/season-tickets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      paypalOrderId,
      season,
    }),
  });
};

export const createOrder = data => {
  return fetch(`${config.api}/api/orders`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export default api;
