export const UPCOMINGS = ['upcomings'];
export const PHOTO_GALLERY = ['photoGallery'];
export const TICKETS = ['tickets'];
export const getShowTicketKey = showId => [...TICKETS, showId];
export const AUDITIONS = ['auditions'];
export const PAST_SHOWS = ['pastShows'];
export const getShowKey = showId => [...PAST_SHOWS, showId];
export const GOALS = ['goals'];
export const VOTES = ['votes'];
export const getVoteKey = showId => [...VOTES, showId];
export const SEASON_TICKETS = ['seasonTickets'];
