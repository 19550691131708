import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './Containers/App';
import CacheBuster from './helpers/CacheBuster';
import * as serviceWorker from './serviceWorker';
import packageJson from '../package.json';
import config from './config';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

if (config.environment === 'production') {
  Sentry.init({
    dsn: 'https://d0e66e9376f44b57b41bf08012562080@o471137.ingest.sentry.io/5502745',
    release: 'polo-theatre-website@' + packageJson.version,
    environment: config.environment,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

root.render(
  <CacheBuster>
    <App />
  </CacheBuster>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
