import { useMutation, useQuery, useQueryClient } from 'react-query';
import api, {
  subscribeBusiness,
  submitAudienceFeedback,
  subscribeNewsletter,
  submitVote,
  purchaseSeasonTicket,
} from '../services/api';
import {
  AUDITIONS,
  GOALS,
  PAST_SHOWS,
  PHOTO_GALLERY,
  SEASON_TICKETS,
  TICKETS,
  UPCOMINGS,
  VOTES,
  getShowKey,
  getShowTicketKey,
  getVoteKey,
} from './keys';

// Gets
export const useGetInterests = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: 'interests',
    queryFn: () => api('/api/newsletter/interests'),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    interests: data,
    isLoading,
    error,
  };
};

export const useGetUpcomings = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: UPCOMINGS,
    queryFn: () => api('/api/upcomings').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    upcomings: data,
    isLoading,
    error,
  };
};

export const useGetPhotoGallery = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: PHOTO_GALLERY,
    queryFn: () => api('/api/photo-gallery?random=true').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    photos: data,
    isLoading,
    error,
  };
};

export const useGetTickets = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: TICKETS,
    queryFn: () => api('/api/tickets').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    tickets: data,
    isLoading,
    error,
  };
};

export const useGetShowTickets = showId => {
  const { isLoading, data, error } = useQuery({
    queryKey: getShowTicketKey(showId),
    queryFn: () => api(`/api/ticket/${showId}`).then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    ticket: data,
    isLoading,
    error,
  };
};

export const useGetAuditions = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: AUDITIONS,
    queryFn: () => api('/api/auditions').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    auditions: data,
    isLoading,
    error,
  };
};

export const useGetPastSeasons = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: PAST_SHOWS,
    queryFn: () => api('/api/past-shows').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    pastSeasons: data,
    isLoading,
    error,
  };
};

export const useGetShow = id => {
  const { isLoading, data, error } = useQuery({
    queryKey: getShowKey(id),
    queryFn: () => api(`/api/show/${id}`).then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    show: data,
    isLoading,
    error,
  };
};

export const useGetGoals = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: GOALS,
    queryFn: () => api('/api/goals').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    goals: data,
    isLoading,
    error,
  };
};

export const useGetSponsors = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: 'sponsors',
    queryFn: () => api('/api/sponsor').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    sponsors: data,
    isLoading,
    error,
  };
};

// Posts
export const useSubscribeNewsletter = () => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: 'subscribeNewsletter',
    mutationFn: async ({
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipCode,
      interests,
    }) => {
      const res = await subscribeNewsletter(
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        interests,
      );

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
    },
    retry: false,
  });

  return {
    submit: mutate,
    isLoading,
    error,
  };
};

export const useSubscribeBusiness = () => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: 'subscribeBusiness',
    mutationFn: async ({
      business,
      contact,
      email,
      address,
      city,
      state,
      zip,
    }) => {
      const res = await subscribeBusiness(
        business,
        contact,
        email,
        address,
        city,
        state,
        zip,
      );

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
    },
    retry: false,
  });

  return {
    submit: mutate,
    isLoading,
    error,
  };
};

export const useSubmitAudienceFeedback = () => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: 'submitAudienceFeedback',
    mutationFn: async feedback => {
      const res = await submitAudienceFeedback(feedback);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
    },
    retry: false,
  });

  return {
    submit: mutate,
    isLoading,
    error,
  };
};

export const useGetVotingOptions = () => {
  const { isLoading, data, error } = useQuery({
    queryKey: VOTES,
    queryFn: () => api('/api/votes').then(res => res.data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    shows: data,
    isLoading,
    error,
  };
};

export const useVote = showId => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation({
    mutationKey: getVoteKey(showId),
    mutationFn: async orderId => {
      const res = await submitVote(orderId, showId);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      queryClient.invalidateQueries(VOTES);
    },
    retry: false,
  });

  return {
    submit: mutate,
    isLoading,
    error,
  };
};

export const usePurchaseSeasonTickets = season => {
  const { mutate, isLoading, error } = useMutation({
    mutationKey: SEASON_TICKETS,
    mutationFn: async orderId => {
      const res = await purchaseSeasonTicket(orderId, season);

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
    },
    retry: false,
  });

  return {
    submit: mutate,
    isLoading,
    error,
  };
};
