import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import config from '../../config';
import { Link } from 'react-router-dom';

function NewsletterModal({ classes }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const showModal = !localStorage.getItem('show-modal');

    let timeout = null;

    if (showModal) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, config.newsletterModalDelay);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const closeModal = () => {
    setOpen(false);
    localStorage.setItem('show-modal', false);
  };

  return (
    <Dialog
      PaperProps={{ className: classes.paper }}
      onClose={closeModal}
      aria-labelledby='newsletter-dialog-title'
      open={open}
    >
      <div className={classes.close} onClick={closeModal}>
        X
      </div>
      <DialogTitle>Receive our Newsletter</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Stay up to date with Polo Area Community Theatre's current events!
          Sign up to receive our newsletter.
        </DialogContentText>
        <DialogActions>
          <Button onClick={closeModal} component={Link} to={'/join-newsletter'}>
            Join
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

const styles = {
  paper: {
    overflowY: 'visible',
  },
  close: {
    top: -20,
    right: -20,
    width: 40,
    height: 40,
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: 40,
    border: '3px solid',
    textAlign: 'center',
    lineHeight: '32px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};

export default withStyles(styles)(NewsletterModal);
