import { forwardRef } from 'react';

const RemoteLink = forwardRef(
  ({ onClick = () => {}, to, children, className }, ref) => {
    return (
      <a
        onClick={onClick}
        ref={ref}
        className={className}
        href={to}
        target='_blank'
        rel='noreferrer'
      >
        {children}
      </a>
    );
  },
);

export default RemoteLink;
