import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

function Loading() {
  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignContent='center'
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default Loading;
