import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Container, withStyles } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import ClearEventHOC from '../ClearEventHOC';
import UseParamHOC from '../UseParamHOC';
import NavBar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import Error from '../Error';
import Loading from '../../Components/Loading';
import NewsletterModal from '../../Components/NewsletterModal';
import { ReactQueryDevtools } from 'react-query/devtools';
import PayPalProvider from '../../Components/PayPalProvider';

function App({ classes }) {
  const Home = lazy(() => import('../Home'));
  const Show = lazy(() => import('../Show'));
  const PastShowGallery = lazy(() => import('../PastShowGallery'));
  const Tickets = lazy(() => import('../Tickets'));
  const Ticket = lazy(() => import('../Ticket'));
  const Donate = lazy(() => import('../Donate'));
  const Policies = lazy(() => import('../Policies'));
  const Scholarships = lazy(() => import('../Scholarships'));
  const Sponsors = lazy(() => import('../Sponsors'));
  const SponsorOption = lazy(() => import('../SponsorOption'));
  const Auditions = lazy(() => import('../Auditions'));
  const AudienceFeedback = lazy(() => import('../AudienceFeedback'));
  const NotFound = lazy(() => import('../NotFound'));
  const JoinNewsletter = lazy(() => import('../JoinNewsletter'));
  const BusinessSponsors = lazy(() => import('../BusinessSponsors'));
  const IndividualSponsors = lazy(() => import('../IndividualSponsors'));
  const Ads = lazy(() => import('../Ads'));
  const VoteForShow = lazy(() => import('../VoteForShow'));
  const OurSponsors = lazy(() => import('../OurSponsors'));

  const queryClient = new QueryClient();

  return (
    <PayPalProvider>
      <QueryClientProvider client={queryClient}>
        <Router basename='/'>
          <NewsletterModal />
          <ClearEventHOC>
            <div className={classes.root}>
              <NavBar />
              <Container className={classes.content}>
                <Sentry.ErrorBoundary fallback={() => <Error />}>
                  <Suspense fallback={<Loading />}>
                    <Routes>
                      <Route exact path='/' element={<Home />} />
                      <Route
                        path='/join-newsletter'
                        element={<JoinNewsletter />}
                      />
                      <Route
                        path='/past_show_gallery/:id'
                        element={
                          <UseParamHOC>
                            <PastShowGallery />
                          </UseParamHOC>
                        }
                      />
                      <Route
                        path='/show/:id'
                        element={
                          <UseParamHOC>
                            <Show />
                          </UseParamHOC>
                        }
                      />
                      <Route
                        path='/tickets/:id'
                        element={
                          <UseParamHOC>
                            <Ticket />
                          </UseParamHOC>
                        }
                      />
                      <Route path='/tickets' element={<Tickets />} />
                      <Route path='/our-sponsors' element={<OurSponsors />} />
                      <Route
                        path='/sponsors-advertisers'
                        element={<Sponsors />}
                      />
                      <Route
                        path='/sponsor/business'
                        element={<BusinessSponsors />}
                      />
                      <Route
                        path='/sponsor/individual'
                        element={<IndividualSponsors />}
                      />
                      <Route path='/sponsor/advertise' element={<Ads />} />
                      <Route
                        path='/sponsors/:id'
                        element={
                          <UseParamHOC>
                            <SponsorOption />
                          </UseParamHOC>
                        }
                      />
                      <Route path='/scholarships' element={<Scholarships />} />
                      <Route path='/donate' element={<Donate />} />
                      <Route path='/policy' element={<Policies />} />
                      <Route path='/auditions' element={<Auditions />} />
                      <Route
                        path='/audience-feedback'
                        element={<AudienceFeedback />}
                      />
                      <Route path='/vote' element={<VoteForShow />} />
                      <Route path='*' element={<NotFound />} />
                    </Routes>
                  </Suspense>
                </Sentry.ErrorBoundary>
              </Container>
              <Footer />
            </div>
          </ClearEventHOC>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PayPalProvider>
  );
}

const styles = {
  root: {
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    paddingBottom: 40,
    marginTop: 40,
    flex: 1,
  },
};

export default withStyles(styles)(App);
