import React from 'react';
import donateImage from '../../images/donate-alt.png';

const DonateButton = ({ width }) => {
  return (
    <form action='https://www.paypal.com/donate' method='post' target='_blank'>
      <input type='hidden' name='hosted_button_id' value='8ZJGJ2JE7WGH6' />
      <input
        width={width}
        type='image'
        src={donateImage}
        border='0'
        name='submit'
        title='PayPal - The safer, easier way to pay online!'
        alt='Donate with PayPal button'
      />
      <img
        alt=''
        border='0'
        src='https://www.paypal.com/en_US/i/scr/pixel.gif'
        width='1'
        height='1'
      />
    </form>
  );
};

export default DonateButton;
