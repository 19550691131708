import React from 'react';
import { useParams } from 'react-router-dom';

function UseParamHOC({ children }) {
  const { id } = useParams();
  const childWithId = React.cloneElement(children, { id });

  return childWithId;
}

export default UseParamHOC;
