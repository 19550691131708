import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Drawer,
  withStyles,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  withWidth,
  Box,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import DonateButton from '../DonateButton';
import logo from '../../images/pact_logo.png';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import youtube from '../../images/youtube.png';
import snapchat from '../../images/snapchat.png';
import SubMenu from '../SubMenu';
import RemoteLink from '../ExternalLink';
import { useGetTickets } from '../../queries/polo';

function TheatreNavbar({ classes, width }) {
  const [openDraw, setDrawState] = useState(false);
  const [openTicketLinks, setOpenTicketLinks] = useState(false);
  const [fixed, setFixed] = useState(false);
  const headerRef = useRef();

  const { tickets } = useGetTickets();

  const menuList = useMemo(() => {
    return [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Past Show Gallery',
        link: '/past_show_gallery/1',
      },
      {
        label: 'Auditions',
        link: '/auditions',
      },
      {
        label: 'Tickets',
        link: '/tickets',
        subLinks:
          tickets?.map(ticket => ({
            label: ticket.name,
            link: `/tickets/${ticket.slug}`,
          })) ?? [],
      },
      {
        label: 'Sponsors & Advertisers',
        link: '/sponsors-advertisers',
      },
      {
        label: 'Scholarships',
        link: '/scholarships',
      },
      {
        label: 'Donate',
        link: '/donate',
      },
      {
        label: 'Shop',
        link: 'https://kaleels.com/product-category/polo-area-community-theatre',
      },
    ];
  }, [tickets]);

  // Remove season tickets from the menu until next season
  if (
    !menuList[3].subLinks.find(subLink => subLink.label === 'Season Tickets') &&
    false
  ) {
    menuList[3].subLinks.unshift({
      label: 'Season Tickets',
      link: '/tickets',
    });
  }

  if (menuList[3].subLinks.length === 0) {
    menuList[3].subLinks.push({
      label: 'No Tickets Available',
      link: '/tickets',
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = () => {
    const { scrollHeight } = headerRef.current;

    if (!fixed && window.scrollY > scrollHeight + 40) {
      setFixed(true);
    }

    if (fixed && window.scrollY - 40 < scrollHeight) {
      setFixed(false);
    }
  };

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawState(open);
    setOpenTicketLinks(false);
  };

  const modalToggleSubItems = event => {
    event.stopPropagation();

    setOpenTicketLinks(current => !current);
  };

  const mobileMenu = () => (
    <Grid container direction='row' justifyContent='flex-end'>
      <Grid item>
        <IconButton edge='end' onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer
          classes={{
            paperAnchorRight: classes.drawer,
          }}
          anchor='right'
          open={openDraw}
          onClose={toggleDrawer(false)}
        >
          <Box
            className={classes.list}
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {menuList.map(item => {
                if (item.subLinks) {
                  return (
                    <div key={item.link}>
                      <ListItem button onClick={modalToggleSubItems}>
                        <ListItemText primary={item.label} />
                      </ListItem>
                      {openTicketLinks && (
                        <List>
                          <Divider />
                          {item.subLinks.map(subLink => (
                            <ListItem
                              key={subLink.link}
                              button
                              component={Link}
                              to={subLink.link}
                            >
                              <ListItemText primary={subLink.label} />
                            </ListItem>
                          ))}
                          <Divider />
                        </List>
                      )}
                    </div>
                  );
                }

                return (
                  <ListItem
                    key={item.link}
                    button
                    component={item.link.startsWith('http') ? RemoteLink : Link}
                    to={item.link}
                  >
                    <ListItemText primary={item.label} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      </Grid>
    </Grid>
  );

  const navBar = () => (
    <Grid
      container
      direction='row'
      justifyContent='space-around'
      className={fixed ? classes.fixedNavBar : classes.navBar}
    >
      {menuList.map(item => (
        <Grid item key={item.link} xs={1}>
          {item.subLinks ? (
            <SubMenu className={classes.navButton} item={item} />
          ) : (
            <Button
              className={classes.navButton}
              component={item.link.startsWith('http') ? RemoteLink : Link}
              to={item.link}
            >
              {item.label}
            </Button>
          )}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <AppBar position='static' className={classes.root}>
      <Toolbar>
        <Grid container justifyContent='space-between' align-items='center'>
          <Grid item xs={12}>
            <Grid container justifyContent='space-between'>
              <Grid item xs={4} md={2} className={classes.logoContainer}>
                <img className={classes.logo} src={logo} alt='logo' />
              </Grid>
              {!(width === 'xs' || width === 'sm') && (
                <Grid item md={8} className={classes.titleContainer}>
                  <Typography variant='h3' className={classes.title}>
                    Polo Area Community Theatre
                  </Typography>
                </Grid>
              )}
              <Grid item xs={4} md={2}>
                <Grid container className={classes.buttonSection} spacing={1}>
                  <Grid item xs={3}>
                    <a
                      href='https://www.facebook.com/poloareacommunitytheatre/'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <img
                        className={classes.linkButton}
                        src={facebook}
                        alt='facebook'
                      />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href='https://www.instagram.com/poloareacommunitytheatre/'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <img
                        className={classes.linkButton}
                        src={instagram}
                        alt='instagram'
                      />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href='https://www.youtube.com/channel/UCs1-4cw3zBIDGJatgQO4S2A/'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <img
                        className={classes.linkButton}
                        src={youtube}
                        alt='youtube'
                      />
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      href='https://www.snapchat.com/add/pactpolotheatre'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <img
                        className={classes.linkButton}
                        src={snapchat}
                        alt='snapchat'
                      />
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.donateButton}>
                      <DonateButton width='100%' />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div ref={headerRef}></div>
          <Grid item xs={12}>
            {(width === 'sm' || width === 'xs') && mobileMenu()}
            {!(width === 'sm' || width === 'xs') && navBar()}
            {!(width === 'sm' || width === 'xs') && fixed && (
              <div style={{ height: 76 }}></div>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const styles = {
  root: {
    backgroundColor: '#86269B',
  },
  logoContainer: {
    marginTop: 20,
  },
  titleContainer: {
    position: 'relative',
  },
  title: {
    color: 'black',
    textAlign: 'center',
    position: 'relative',
    top: '50%',
    fontFamily: 'Lobster, cursive',
  },
  donateButton: {
    marginTop: 66,
    marginBottom: -20,
    maxWidth: 256,
  },
  drawer: {
    backgroundColor: '#f4abff',
  },
  list: {
    width: 250,
  },
  ListItem: {
    color: 'inherit',
  },
  logo: {
    width: '100%',
    maxWidth: 200,
  },
  text: {
    display: 'inline',
    marginLeft: 5,
  },
  buttonSection: {
    marginTop: 20,
  },
  linkButton: {
    width: '100%',
    maxWidth: 60,
  },
  navBar: {
    marginTop: 40,
  },
  fixedNavBar: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5,
    background: '#86269B',
  },
  navButton: {
    width: '100%',
    '&:hover': {
      color: 'white',
    },
  },
};

export default withWidth()(withStyles(styles)(TheatreNavbar));
