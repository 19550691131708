import { useLocation } from 'react-router-dom';

function ClearEventHOC({ children }) {
  useLocation();

  const eventScript = document.getElementById('event-script');

  if (eventScript) {
    eventScript.parentNode.removeChild(eventScript);
  }

  return children;
}

export default ClearEventHOC;
