import Loading from '../Loading';
import config from '../../config';
import { useEffect, useState } from 'react';

function PayPalProvider({ children }) {
  const [isSdkReady, setIsSdkReady] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${config.paypalClientId}&components=buttons&disable-funding=card,paylater`;
    script.onload = () => setIsSdkReady(true);

    document.head.appendChild(script);
  }, []);

  if (!isSdkReady) {
    return <Loading />;
  }

  return <>{children}</>;
}

export default PayPalProvider;
